<template>
  <div
      class="d-inline-block"
      v-if="ifHasPermission(['pm-add-notes'], data.project_id)"
  >
    <custom-button
        :attributes="meta.button"
        :content="field.label && field.label.name ? $t(field.label.name) : ''"
        @click.native="toggle"
    />
    <transition name="el-fade-in">
      <note-form
          :id="data.id"
          :projectId="data.project_id"
          :saveURL="saveURL"
          @cancel="toggle"
          @saved="saved"
          class="popover--container"
          style="width: 90%"
          v-if="show"
      />
    </transition>
  </div>
</template>

<script>
import abstractForm from '../../mixin/index';
import button from '../../mixin/button';
import CustomButton from '../../UI/Button.vue';
import NoteForm from './noteForm.vue';
import EventBus from '../../../../../eventBus';

export default {
  name: 'index',
  mixins: [abstractForm, button],
  components: {NoteForm, CustomButton},
  methods: {
    saved(data) {
      EventBus.$emit('FETCH_NOTES');
      EventBus.$emit('FETCH_ACTIVITIES');
      this.onSaved(data);
    },
  },
};
</script>

<!--<style rel="stylesheet/scss" lang="scss" scoped>-->
<!--  .popover&#45;&#45;container{-->
<!--    padding: 10px;-->
<!--    position: absolute;-->
<!--    top: 55px;-->
<!--    background-color: rgb(254, 255, 255);-->
<!--    width: 80%;-->
<!--    z-index: 1;-->
<!--    border-radius: 5px;-->
<!--    box-shadow: 0 0 14px rgba(0,0,0,.1);-->
<!--  }-->
<!--</style>-->
